.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.accordion-header {
  --bs-accordion-active-bg: var(--bs-secondary-bg) !important;
}

.accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
}
