.banner {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

@media (height < 700px) {
  .banner {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
}
